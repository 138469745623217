// HEADER Layout
header {
	background-color : @brand-primary-one !important;
	//height : 100vh !important;
	width : 100% !important;
	position : absolute !important;
	top : 0px !important;
	z-index : 1000;
        
    .boxshadow;
	
	.container {
		margin : 0px auto 0px auto !important;
		max-width : 1500px !important;

		.innercontainer {
			padding : 0px 10px;
			.transition-header-zeropointone;
		}
		
		&.nopad {
			margin : 0px 0px 0px 0px !important;
			max-width : 100% !important;
			background-color : @brand-primary-one-moretrans;
			
			.innercontainer {
				margin : 0px auto 0px auto !important;
				padding : 0px 0px !important;
				max-width : 1500px !important;
			}
		}
		
		
		@media only screen and (min-width: 1510px) {
		.innercontainer {
			padding : 0px 0px;
		}
		}
	}
	
	&.banner {
	    //height : 103px !important;
	    //background-color : @white-trans;
    	//box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.5);
	}	    
    @media only screen and (min-width: 0px) and (max-width: @screen-sm) {
	&.banner {
		//height : 110px !important;
	}
	}
}
// Move content down to accomodate Wordpress bar
.logged-in header {
	top : 32px !important;
}

.navbar-static-top {
	border-bottom : 0px none;
}


// -------------------------------------------
// HEADER - Homepage
.home {
	
	header {
		//background-color : @white-trans !important;
		//margin-top : 32px;
		//position : absolute !important;
		//top : 0px !important;
		//height : 100vh !important;
		//width : 100% !important;
		//background-color : @brand-primary-one-trans !important;
	}
}


// -------------------------------------------
// Main logo
.tophead {
    margin : 0px 0px 0px 0px;
    padding : 0px 0px;
    
    a.navbar-brand {
        margin : 25px 0px 0px 0px !important;
        padding : 0px 0px 0px 0px !important;
        height : 180px;
        width : 120px;
        display : block;
        z-index : 10;
        background : url("../img/logo_head_link_120x180px.svg") no-repeat top center;
        background-size : cover;
        
        position : absolute;
        top : 0px;
        left : 0px;
        
        &:hover {
            //background : url("../img/logo_head_link.png") no-repeat bottom left;
        }
        
        h1 {
            span {
                display : none;   
            }
        }
    }
    &.fasftoplogo {
        a.navbar-brand {
            //background : url("../img/logo_head_FA6th_link.fw.png") no-repeat top left;
            &:hover {
                //background : url("../img/logo_head_FA6th_link.fw.png") no-repeat bottom left;
            }
        }
    }
}
// Make logo center-aligned on XS screens
@media only screen and (min-width: 0px) and (max-width: @screen-sm) {
.tophead {
	a.navbar-brand {
        height : 145px;
        width : 100px;
	}
}
}


// -------------------------------------------
// Top right links 
.toplinks {
	.zeropm;
	
	.maintoplinks {
		float : right;
	}
	.searchtoplinks {
		float : right;
	}
}
@media only screen and (min-width: 0px) and (max-width: @screen-sm) {
.toplinks {
    .maintoplinks {
    	float : none;
    }
}
}
	
.toprighttext {
	.zeropm;
	text-align : right;
    font-weight : 700 !important;
    font-size : 12px !important;
    white-space: nowrap;
}



// ---------------------------------------------
// REFERENCE
// For main navigation styles - see MainNavigation.less
// For search related styles - see Search.less