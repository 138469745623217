// -------------------------------------------
// Styles originally set for Homepage, but also contains
// Content Page related content elements (courses, subjects, etc.)
// -------------------------------------------



// -------------------------------------------
// NEWS POSTS
// See ContentArea.less for Content Pages Further Posts / News box styling
.newshomeposts {
	margin-top : 20px;
	padding-left : 0px;
	padding-right : 0px;
	padding-top : 0px;
	//padding-bottom : 50px;
	//background-color : @brand-primary-one-shade;
	
	transition : all 0.5s;
	
	.nhpintrobox {
		padding : 20px 0px;
		font-family : @font-family-serif;
		color : @brand-primary-one;
		
		h3 {
			.zeropm;
		}
	}
	
	&.darkbg {
		//background-color : @brand-primary-one;
	}
	
	// Content page specific styles
	&.contentpages {
		margin-bottom : 40px;
	}
	
	.nfheader {
		padding-bottom : 10px;
		//background-color : @brand-primary-one-trans !important;
		text-align : left;
		
		.nftitle {
			.zeropm;
			
			h3 {
				margin-top : 0px;
				margin-left : 30px;
				padding-top : 20px;
				padding-bottom : 0px;
				color : @brand-primary-one;
				font-size : 2em;
				
				small {
					font-size : 0.5em;
				}
			}
		}
		.nflink {
			.zeropm;
			
			a {
				margin-top : 85px;
				margin-right : 65px;
			}    			
		}
	}
	
	.nfpcontent {
		padding : 0px 0px 0px 0px;
		
		transition : all 0.5s;
		
	    display : flex;
	    flex-wrap : wrap;
		
		h3 {
			margin-left : 20px;
			margin-bottom : 30px;
		}
		
		.nfparticle {
			.zeropm;
			
			transition : all 0.5s;
		
			&.first {
				background-color : @brand-primary-two;
				.newsimage {
					border-color : @brand-primary-two;
				}
			}
			&.second {
				background-color : @brand-primary-four;
				.newsimage {
					border-color : @brand-primary-four;
				}
			}

			a {
				display : block;
				
				&:hover {
					text-decoration : none;
					
					.nfpover {
						
						.nfpoverwords {
		    				opacity: 1.0;
		    				filter: alpha(opacity=100);
							background-color : fade(@black-moretrans, 40%);
						}
					}
				}
				
				.nfpover {
					margin : 10px 10px;
					position : relative;
						
					.nfpoverwords {
						padding-left : 10px;
						padding-top : 100px;
						width : 100%;
						font-family : @font-family-serif;
						font-size : @font-size-h2;
						text-align : center;
						color : @white;
						background-color : fade(@black-trans, 0%);
						
						position : absolute;
						top : 0px;
						left : 0px;
						
						transition : all 0.5s;
						
	    				opacity: 0.0;
	    				filter: alpha(opacity=0);
	    				
	    				.glyphicon {
	    					position : relative;
	    					top : 7px;
	    				}
						
					}
				}
			}
			
			.newsimage {
				//margin-bottom : 10px;
				background-size : cover !important;
	    		background-position : center center !important;
	    		position : relative;
				display : block;
				height : 300px;
			}
			
			.newswords {
				padding : 10px 0px 10px 0px;
				color : @brand-primary-one;
				font-family : @font-family-serif;
				
				h4 {
					margin-top : 0px;
					margin-bottom : 0px;
					padding : 0px 10px;
    				font-size : @font-size-h4;
    				text-align : left;
				}
				.newsdatestamp {
					padding : 5px 10px 5px 10px;
					font-size : @font-size-small;
					line-height : @font-size-small !important;
					color : @gray-light;
					
					.eventdate {
						margin-bottom : 0px;
						line-height : @font-size-base !important;
					}
					.eventtime {
						margin-bottom : 0px;
						line-height : @font-size-base !important;
					}
				}
				.newswordscont {
					padding : 5px 10px 10px 10px;
					font-size : 14px;
    				line-height : 16px !important;
    				height : 110px;
    				overflow-y : hidden;
    				
    				p {
    					padding-top : 0px;
    					font-size : 14px;
	    				line-height : 17px !important;
    				}
				}
			}
			
		}

		&.schoolinfo { //School Information page colour variations
			.nfparticle {
				a {
					&:hover {
						.nfpover {
							.nfpoverwords {
								background-color : fade(@brand-primary-two-trans, 40%);
							}
						}
					}
					
					.nfpover {
						.nfpoverwords {
							background-color : fade(@brand-primary-two-trans, 0%);						
						}
					}
				}
			}
		}

	}
	.oldposts {
		//background-color : @gray-lighter;
		
		h2 {
			.zeropm;
			color : @brand-primary-one !important;
			text-transform : uppercase;
			font-weight : 700;
			font-size : 50px !important;
			text-align : center;
			letter-spacing: 2px;
			margin-bottom : 40px;
			margin-top : 20px;
			padding-top : 40px;
		}
	}	
	
	// Home News Box height values - SEE BELOW FOR ADDITIONAL VALUES
	@homenewsheight-xs : 400px;
	@homenewsheight-sm : 200px;
	@homenewsheight-md : 250px;
	@homenewsheight-lg : 300px;
	@media (min-width: @screen-xs-min) {
	.nfpcontent {
		.nfparticle {
			margin-bottom : 30px;
			.newsimage {
				height : @homenewsheight-xs;
			}
			.nfpover {
				//min-height : 600px;
				min-height : @homenewsheight-xs;
				.nfpoverwords {
					height : @homenewsheight-xs;
				}
			}
		}
	}
	} 
	@media (min-width: @screen-sm-min) { 
	.nfpcontent {
		.nfparticle {
			margin-bottom : 20px;
			.newsimage {
				height : @homenewsheight-sm;
			}
			.nfpover {
				//min-height : 370px;
				min-height : @homenewsheight-sm;
				.nfpoverwords {
					height : @homenewsheight-sm;
				}
			}
		}
	}
	} 
	@media (min-width: @screen-md-min) { 
	.nfpcontent {
		.nfparticle {
			margin-bottom : 30px;
			.newsimage {
				height : @homenewsheight-md;
			}
			.nfpover {
				//min-height : 450px;
				min-height : @homenewsheight-md;
				.nfpoverwords {
					height : @homenewsheight-md;
				}
			}
		}
	}
	} 
	@media (min-width: @screen-lg-min) { 
	.nfpcontent {
		.nfparticle {
			margin-bottom : 30px;
			.newsimage {
				height : @homenewsheight-lg;
			}
			.nfpover {
				//min-height : 500px;
				min-height : @homenewsheight-lg;
				.nfpoverwords {
					height : @homenewsheight-lg;
				}
			}
		}
	}
	}
	
	// Remove side padding on heading for wide screens
	@media (min-width: 1520px) { 
	.nfheader {
		.nftitle {
			h3 {
				//margin-left : 0px;
			}
		}
		.nflink {
			a {
				//margin-right : 0px;
			}    			
		}
	}
	}


	// Subjects listing
	&.subjectslist {
		//border : 2px dotted red;

		.nfparticle {
			a {
				&:hover {
					.nfpover {
						.nfpoverwords {
		    				opacity: 1.0;
		    				filter: alpha(opacity=100);
							background-color : fade(@brand-primary-two-trans, 40%);
						}
					}
				}
				.nfpover {
					.nfpoverwords {
						color : @white;
						background-color : fade(@brand-primary-two-trans, 0%);
					}
				}
			}
		}
	}
	
	// Courses listing
	&.courseslist {
		//border : 2px dotted green;

		.nfparticle {
			a {
				&:hover {
					.nfpover {
						.nfpoverwords {
		    				opacity: 1.0;
		    				filter: alpha(opacity=100);
							background-color : fade(@brand-primary-four-trans, 40%);
						}
					}
				}
				.nfpover {
					.nfpoverwords {
						color : @white;
						background-color : fade(@brand-primary-four-trans, 0%);
					}
				}
			}
		}
	}

	// Subjects & Courses listing common styles
	&.subjectslist, &.courseslist {
		
		
		// Subjects & Courses height values 
		@relatedcontentheight-xs : ceil((@homenewsheight-xs * 0.90));
		@relatedcontentheight-sm : ceil((@homenewsheight-sm * 0.80));
		@relatedcontentheight-md : ceil((@homenewsheight-md * 0.70));
		@relatedcontentheight-lg : ceil((@homenewsheight-lg * 0.70));	
		
		@media (min-width: @screen-xs-min) {
		.nfpcontent {
			.nfparticle {
				.newsimage {
					height : @relatedcontentheight-xs;
				}
				.nfpover {
					min-height : @relatedcontentheight-xs;
					.nfpoverwords {
						height : @relatedcontentheight-xs;
					}
				}
			}
		}
		} 
		@media (min-width: @screen-sm-min) { 
		.nfpcontent {
			.nfparticle {
				.newsimage {
					height : @relatedcontentheight-sm;
				}
				.nfpover {
					min-height : @relatedcontentheight-sm;
					.nfpoverwords {
						height : @relatedcontentheight-sm;
					}
				}
			}
		}
		} 
		@media (min-width: @screen-md-min) { 
		.nfpcontent {
			.nfparticle {
				.newsimage {
					height : @relatedcontentheight-md;
				}
				.nfpover {
					min-height : @relatedcontentheight-md;
					.nfpoverwords {
						height : @relatedcontentheight-md;
					}
				}
			}
		}
		} 
		@media (min-width: @screen-lg-min) { 
		.nfpcontent {
			.nfparticle {
				.newsimage {
					height : @relatedcontentheight-lg;
				}
				.nfpover {
					min-height : @relatedcontentheight-lg;
					.nfpoverwords {
						height : @relatedcontentheight-lg;
					}
				}
			}
		}
		}
		
	}

	// FOR PAGES WHERE DATE AND EXCERPT ARE SET TO HIDDEN
	&.contentpages {
		&.hideinfo {
			
			.newsdatestamp {
				display : none;
				visibility : hidden;
			}
			.newswordscont {
				display : none; 
				visibility : hidden;
			}
			
			// Home News Box height values - SEE ABOVE FOR INITIAL VALUES
			@media (min-width: @screen-xs-min) {
			.nfpcontent {
				.nfparticle {
					.nfpover {
						min-height : 450px;
					}
				}
			}
			} 
			@media (min-width: @screen-sm-min) { 
			.nfpcontent {
				.nfparticle {
					.nfpover {
						min-height : 250px;
					}
				}
			}
			} 
			@media (min-width: @screen-md-min) { 
			.nfpcontent {
				.nfparticle {
					.nfpover {
						min-height : 300px;
					}
				}
			}
			} 
			@media (min-width: @screen-lg-min) { 
			.nfpcontent {
				.nfparticle {
					.nfpover {
						min-height : 350px;
					}
				}
			}
			}
		}
	}
	
	
	// EVENTS LISTING VARIATIONS
	&.hpeventslist {
		
		.newsimage {
			
		}
		.newswords {
			background-color : @brand-primary-five;
			color : @white;
			
			h4 {
				color : @brand-primary-two;
			}
			.eventdate, .eventtime {
				color : @white;
			}
		}
	}
}



// -------------------------------------------
// Edit Post button
.nfparticle {
	.editbutton {
		.btn {
			position : absolute;
			top : 30px;
			right : 30px;
			z-index : 400;
		}
	}
}


// -------------------------------------------
// SAFEGUARDING TEMPLATE
.page-template-template-safeguarding {
	
	.rhscontent {
		
		.well {
			margin-bottom : 10px;
			
			&.meetthesenco {
				
				.listthumb {
					width : 100px;
					height : 150px;
				}
				p {
					line-height: @font-size-base !important;
				}
			}
		}
	}
}


// -------------------------------------------
// GOVERNORS TEMPLATE
// Profile Buttons
.govbuttons {
	margin-bottom : 10px;
	
	.govbuttonslink {
		width : 100%;
		
		p {
			padding-top : 10px;
			font-family : @font-family-serif;
			color : @white;
			font-size : @font-size-base;
			line-height : @font-size-base;
			
			small {
				font-family : @font-family-sans-serif;
				color : @brand-primary-two;
				font-size : @font-size-smaller;
			}
		}
	}
}
//Profile Windows
.govsprofile {
	
	.modal-dialog {
		
		.modal-content {
			
			.modal-header {
				position : relative;
				
				h1 {
					padding-top : 20px;
					margin-bottom : 10px;
					font-family : @font-family-serif;
					font-size : @font-size-h3;
					line-height : @font-size-h2;
					
					small {
						font-family : @font-family-sans-serif;
						color : @brand-primary-two;
						font-size : @font-size-h3;
					}
				}

				.govphoto {
					margin-right : 10px;
					width : 100px;
					height : 100px;
					background-repeat : no-repeat;
					background-size : cover;
					background-position : center center;
					float : left;
					border-radius : 5px;
				}
				.chairtitle {
					//color : @brand-primary-five;
				}

				// Edit Button
				.post-edit-link {
					position : absolute;
					top: 50px;
					right : 20px;
					
					.btn {
						margin-bottom : 5px;
					}
				}
			}

			.modal-body {
				
				.well {
					
					h3 {
						margin-top : 0px;
						margin-bottom : 10px;
						font-size : @font-size-h4-cont !important;
					}
				}
			}
		}
	}
}

// -------------------------------------------
// NEWS POSTS

// News Room Template
.nwlolder {
	background-color : @brand-primary-two-light;
}


// -------------------------------------------
// TERM DATES TEMPLATE
.termdatestable {
	border: 1px solid #DDDDDD;
	background-color : #FFFFFF;
	width : 100%;
	
	th {
		padding : 5px 10px;
		background-color : #DDDDDD;
		border-top : 1px solid #EEEEEE;
		
		&.termheading {
			background-color : #EEEEEE;
		}
	}
	td {
		padding : 5px 10px;
		border-top : 1px solid #DDDDDD;
	}
}


// -------------------------------------------
// "THE EVENTS CALENDAR" STYLES
#tribe-events-content {
	
	.tribe_events-calendar {
		
		h3 {
			//font-size : @font-size-h3-cont !important;
			
			&.tribe-events-month-event-title {
				//font-size : @font-size-h3-cont !important;
			}
		}
	}
}


// -------------------------------------------
// Download Lists Main Styles
ul.documents {
	.zeropm;
	columns : 2;
	-webkit-columns : 2;
	-moz-columns : 2;
	
	li {
		.zeropm;
		//padding : 10px 10px 10px 10px;
		margin : 0px 0px 5px 0px;
		list-style-type : none !important;
		background-color : @brand-primary-one;
		border : 10px solid @brand-primary-one;
		border-radius : 3px;
		color : @white;
		//font-family : @font-family-serif;
		font-weight : normal !important;
		font-size : @font-size-large;
		text-align : left;
		-webkit-column-break-inside : avoid; // Solves wrap issue on list item in first column
		
		.glyphicon {
			color : @brand-primary-one;
			font-weight : normal !important;
			background-color : @white;
			border : 6px solid @white;
			border-radius : 50%;
			margin-right : 5px;
		}
		
		a {
			color : @white;
			//font-weight : normal !important;
			
			&:hover {
				color : @brand-primary-two;
			}
		}
	}
}
// Content page version for RHS column
.righthandside {
	
	ul.documents {
		columns : 1;
		-webkit-columns : 1;
		-moz-columns : 1;
		
		li {
			font-size : @font-size-small;
		}
	}
}