/*!
 * Roots 6.5.2
 */

@import "bootstrap/bootstrap.less";
@import "bootstrap/single-event.less";


/* ==========================================================================
   Base
   ========================================================================== */

html, body {
// Added to control overall styling - GE
    font-family : @font-family-base !important;
    height : 100%;

    h1, h2, h3, h4, h5 {
        font-weight : normal !important;
    }
    h1 {
        font-size : 38px !important;
    }
    strong, b {
        font-weight: bold;
    }

    .btn-super-lg {
        font-weight : normal !important;
        font-size : 28px !important;
    }
} 

body { 

    .download-btn {
        font-size: 40px;
        padding: 10px;
        background: @brand-primary-one;
        color: white;
    }

    .panel {
        border: 0px;
    }

}



/* ==========================================================================
   Header
   ========================================================================== */

.banner {
// All below added to control menu styling - GE
    //font-size : @font-size-base !important;
    //font-size : ceil((@font-size-base * 0.85));
    font-size : ceil((@font-size-base * 0.75));
    
    .dropdown-menu {
        -webkit-box-shadow : 0 0px 0px !important;
        box-shadow : 0 0px 0px !important;
        border : 0px none !important;
        border-radius : 2px !important;
        //font-size : ceil((@font-size-base * 0.85));
        font-size : ceil((@font-size-base * 0.75));
    }
}


/* ==========================================================================
   Content
   ========================================================================== */

.content { 
// Added to make sure Footer doesn't creep up page
    //min-height : 700px !important;
    padding-left : 0px;
    padding-right : 0px;
    
    //background-color : @brand-primary-one;
    
    // Background image styles
    .bgtrans {
        padding-left : 0px;
        padding-right : 0px;
        background-position : center center;
        background-size : cover;
		background-repeat : no-repeat;
		background-attachment : fixed;
        //min-height : 600px;
        min-height : 400px;
        //height : 100vh;
        position : relative;
		
		&.noheaderbg {
			//height : 150px;
			min-height : 300px !important;
			background-size : 15%;
			background-repeat : repeat;
			background-image : url('../img/nobg.png');
			
			&.disabledfi {
				min-height : 350px !important;
			}
			&.nofi {
				min-height : 350px !important;
			}
    	}
    }
    .bgtransoverlay {
        //.gradient-whitetrans;
        padding-left : 20px;
        padding-right : 20px;
        min-height : 700px;
        position : relative; 
        top : 0px; 
        padding-top : 10px;
    }

    // Take out spacing at side of pages - make full screen (includes next section)   
    &.row {
        margin-left : 0px !important;
        margin-right : 0px !important;
        //min-height : 400px;
        background-position : center;
            
        .main {
            padding-left : 0px;
            padding-right : 0px;
            //min-height : 300px;
        }
    }
}


@media only screen and (min-width: 0px) and (max-width: @screen-xs) {
.content {
    .bgtransoverlay {
    	margin-top : 230px;
    }
}
}
@media only screen and (min-width: @screen-xs) and (max-width: @screen-sm) {
.content {
    .bgtransoverlay {
    	margin-top : 230px;
    }
}
}

@media only screen and (min-width: 0px) and (max-width: @screen-xs) {
.content {
    padding-left : 0px;
    padding-right : 0px;
    .bgtransoverlay {
        padding-left : 0px;
        padding-right : 0px;
    }
    &.row {
        margin-left : 0px !important;
        margin-right : 0px !important;
        min-height : 400px;
        background-position : center;
            
        .main {
            padding-left : 0px;
            padding-right : 0px;
            min-height : 300px;
        }
    }
}
}


/* ==========================================================================
   Primary Content
   ========================================================================== */

.main { 
	//.gradient-contentbgbackground;

    p {
        line-height: 27px;
    }
 
    .aboutuspage {
        .alignleft { 
            margin-right: 20px;
        }
    }

}

/* Gallery Shortcode */
.gallery-row { padding: 15px 0; }



/* ==========================================================================
   Sidebar
   ========================================================================== */

.sidebar { }



/* ==========================================================================
   Posts
   ========================================================================== */

.hentry header { }
.hentry time { }
.hentry .byline { }
.hentry .entry-content { }
.hentry footer { }



/* ==========================================================================
   Footer
   ========================================================================== */

.content-info { }



/* ==========================================================================
   WordPress Generated Classes
   See: http://codex.wordpress.org/CSS#WordPress_Generated_Classes
   ========================================================================== */

.aligncenter { display: block; margin: 0 auto; }
.alignleft { float: left; }
.alignright { float: right; }
figure.alignnone { margin-left: 0; margin-right: 0; }



/* ==========================================================================
   Media Queries
   ========================================================================== */

@media (min-width: @screen-sm-min) { 

    .col-container {
        height: auto;

        p, a { 
            font-size:13px;
        }
    }

}

@media (min-width: @screen-md-min) { 

    .col-container {
        height: auto;

        p, a {
            font-size:16px;
        }
    }
}

@media (min-width: @screen-lg-min) { 

    .col-container {
        height: 250px;
        overflow: hidden;

        p, a {
            font-size:16px;
        }
    }

}


// GE Styles
// ==========================================================================

// Page divider
.divider {
    width : 100%;
    clear : both;
}

// Solve grey BG colour issue
body,
html {
    height : auto !important;
}

.container {
    .zeropm !important;
    width : 100% !important;
}

// Hidden Class
.displayhidden {
    display : none;
    visibility : hidden;
}

// Zero margin and padding
.zeropm {
    margin : 0px 0px;
    padding : 0px 0px;
}

// Line under navigation bar
.banner.navbar.navbar-fixed-top.navbar-default.navbar-static-top.jt-header {
    border-bottom : 0px none;
}

// Hyphenated content
.hyphenated {
    -ms-word-break: break-all;
     word-break: break-all;

     // Non standard for webkit
     word-break: break-word;

-webkit-hyphens: auto;
   -moz-hyphens: auto;
        hyphens: auto;
}

// Make navbar not fixed on small screens
@media only screen and (min-width: 0px) and (max-width: 600px) {
    .navbar-fixed-top {
        position : absolute !important;
        top : 0px !important;
        margin-top : auto !important;
    }
    .logged-in .navbar-fixed-top {
        position : absolute !important;
        top : 13px !important;
        margin-top : auto !important;
    }
}
@media only screen and (min-width: 600px) and (max-width: 780px) {
    .navbar-fixed-top {
        top : 0px !important;
    }
    .logged-in .navbar-fixed-top {
        top : 13px !important;
    }
}




// General Styles
.top {
    padding-bottom : 20px;
    padding-top : 20px;

    h1 {
        .zeropm;
        color : @brand-primary-one !important;
        text-transform : uppercase;
        font-size : 50px;
    }
}


// Breadcrumb (content pages)
.breadcrumb {
    text-align : left;
    background-color : @brand-primary-six;
    border-radius : 0px;
    
    a {
        color : @brand-primary-two !important;
        &:hover {
              color : @brand-primary-two-dark !important;
              text-decoration : none !important;
        }
    }
}




// Content area layout 
// See ContentArea.less for content area styling - Elements should now be in that file 

// GENERAL CONTENT STYLES
.gencontstyles {

    &.agendatablepage {
        .row {
            max-width : 100%;   
        }
    }
    .row {
        margin : 0px auto 20px auto;
        padding-left : 20px;
        padding-right : 20px;
        max-width : 900px;
        color : @brand-primary-one-dark;
        font-size : 16px !important;
        line-height : 22px !important;
        text-align : justify;
        
        p {
            .zeropm;
            margin-bottom : 20px;
            max-width : 900px;
            color : @brand-primary-one-dark;
            font-size : 16px !important;
            line-height : 22px !important;
            text-align : justify;
        }
        a {
            color : @brand-primary-three;
            &:hover {
                color : @brand-primary-three-dark;
            }
        }
        ul {
            padding-left : 20px;
            margin-bottom : 20px;
            li {
                margin-bottom : 10px;   
            }
        }
        pre {
            .zeropm;
            margin : 30px auto;
            padding-left : 20px;
            padding-right : 20px;
            max-width : 900px;
            font-size : 16px;
            color : #00b8b7;
            background-color : #ebffff;
            border : 1px solid #00b8b7;
            text-align : left;
        }
        address {
            .zeropm;
            margin : 10px auto 10px auto;
            padding-left : 20px;
            padding-right : 20px;
            padding-bottom : 10px;
            max-width : 900px;
            text-align : left;
            font-style : italic;
        }
        
        h1,h2,h3,h4,h5,h6 {
            .zeropm;
            margin : 30px auto 10px auto;
            max-width : 900px;
            color : @brand-primary-three;
            text-align : center;
            text-transform : uppercase;
            //font-family : @font-family-base;
            //font-family : @font-family-bebas-bold;
        }
        h1 {
            font-size : 45px !important;
            padding-left : 20px;
            padding-right : 20px;
        }
        h2 {
            font-size : 40px !important;
            padding-left : 20px;
            padding-right : 20px;
        }
        h3 {
            font-size : 30px !important;
            text-align : left;
        }
        h4 {
            font-size : 23px !important;
            text-align : left;
        }
        h5 {
            font-size : 20px !important;
            text-align : left;
        }
        h6 {
            font-size : 20px !important;
            text-align : left;
        }
    }
}

// FURTHER CONTENT STYLES
// Link list 
.contentlinklist {
    list-style-type : none;
    padding-left: 0px;
    border-top : 1px solid @brand-primary-two;
    border-bottom : 1px solid @brand-primary-two;
    
    li {
        padding : 10px 15px;
        
        &:nth-child(odd){
            .gradient-lightblue;
        }
    }
}

// Top menu bottom line fix
#menu-primary-navigation {
    .active {
        border-bottom : 0px none !important;
    }
}

// DEPARTMENTS LIST PAGE
.page-template-template-departments-php {
    footer {
        margin-top : 0px;
    }
}
.deptslayout {
border : 10px soild red;
    .deptslist {
   
        .deptslistbox {
            .zeropm;
            
            .deptsbox {
                .zeropm;
                min-height : 300px;
                position : relative;
                
                .depttitleexcerpt {
                    .zeropm;
                    background-color : rgba(255,255,255,0.5);
                    position : absolute;
                    bottom : 0px;
                    width : 100%;
                    
                    h3 {
                        margin-top : 0px;
                        margin-bottom : 10px;
                    }
                }
                .deptsnoimage {
                    .depttitleexcerpt {
                        background-color : transparent;
                    }
                }
            }
        }
        .col-xs-12 {
            padding-left : 0px;
            padding-right : 0px;
        }    
        .col-sm-6 {
            padding-left : 0px;
            padding-right : 0px;
        }   
        .col-md-4 {
            padding-left : 0px;
            padding-right : 0px;
        }   
        .col-lg-3 {
            padding-left : 0px;
            padding-right : 0px;
        }
    }
}

// LIST ELEMENTS - POST TYPE PAGES
.homespotlist {
    border-bottom : 1px solid @white;
    padding-left : 0px;
    
    a { 
        display : block;
        
        &:hover {
            text-decoration : none;
        }
    }
    
    li {
        padding : 10px 0px 10px 0px;
        
        list-style-type : none;
        width : 100%;
        clear : both;
        border-top : 1px solid @white;
        
        background-color : rgba(255,255,255,0);
        
        -webkit-transition : background-color 0.75s; /* For Safari 3.1 to 6.0 */
        transition : background-color 0.75s;
        transition-timing-function : ease-in-out;
        
        &:hover {
            background-color : rgba(255,255,255,0.25);   
        }
        
        .listthumb {
             margin-left : 10px;
             position : relative;
             right : 5px;
             background-position : center;
             width: 80px;
             height: 80px;
             float : right;
             
             background-size : cover;
             
             //-webkit-border-radius: 38px;
             //-moz-border-radius: 38px;
             //border-radius: 38px;
        }
        .listwords {
            padding-left : 5px;
        }
    }
}

// COLOUR BGs FOR CONTENT BOXES (plus cascading styles)
.boxbgfasfblue {
     .gradient-fasfblue !important;
     //border : 1px solid @brand-primary-fasfblue;
     .homespotlist {
        border-bottom : 1px solid @brand-primary-fasfblue-light;
        a { 
            color : @white;
            &:hover {
                
            }
        }
        li {
            border-top : 1px solid @brand-primary-fasfblue-light;
        }
    }
}
.boxbggreen {
     .gradient-green !important;
     //border : 1px solid @brand-primary-one;
     .homespotlist {
        border-bottom : 1px solid @brand-primary-one-light;
        a { 
            color : @white;
            &:hover {
                
            }
        }
        li {
            border-top : 1px solid @brand-primary-one-light;
        }
    }
}
.boxbgswsblue {
     .gradient-swsblue !important;
     //border : 1px solid @brand-primary-two;
     .homespotlist {
        border-bottom : 1px solid @brand-primary-two-light;
        a { 
            color : @white;
            &:hover {
                
            }
            small {
                color : @brand-primary-two-light !important; 
            }
        }
        li {
            border-top : 1px solid @brand-primary-two-light;
        }
    }
}
.boxbgdarkblue {
     .gradient-darkblue !important;
     //border : 1px solid @brand-primary-three;
     .homespotlist {
        border-bottom : 1px solid @brand-primary-three-light;
        a { 
            color : @white;
            &:hover {
                
            }
        }
        li {
            border-top : 1px solid @brand-primary-three-light;
        }
    }
}
.boxbgbrown {
     .gradient-brown !important; 
     //border : 1px solid @brand-primary-five;
     .homespotlist {
        border-bottom : 1px solid @brand-primary-five-light;
        a { 
            color : @white;
            &:hover {
                
            }
        }
        li {
            border-top : 1px solid @brand-primary-five-light;
        }
    }
}
.boxbglightbrown {
     .gradient-lightbrown !important;
     //border : 1px solid @brand-primary-six;
     .homespotlist {
        border-bottom : 1px solid @brand-primary-six-light;
        a { 
            color : @white;
            &:hover {
                
            }
        }
        li {
            border-top : 1px solid @brand-primary-six-light;
        }
    }
}

// NEWS LIST STYLES
.newslist {
    border-top : 1px solid @gray-light;
    
    li {
        padding : 10px;
        .gradient-lightgrey !important;
        border-bottom : 1px solid @gray-light;
        &:hover {
            background-color : @white !important;
        }
        h4 {
            .zeropm;
            margin-bottom : 10px;
            font-size : 20px;
        }
       .listwords {
            padding : 10px;
            border : 0px none;
            background-color : @white !important;
            
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            border-radius: 3px;
            
            font-size : 14px !important;
            line-height : 18px !important;
        }
        .listthumb {
            top : 10px; 
        }
    }
}


// CONTENT PAGES - CONTENT BOXES STYLING
.contenttypeboxframe {
    
    .contenttypebox {
        //min-height : 600px;
        padding-left : 0px;
        padding-right : 0px;
        
        .innerblock {
            //.gradient-lightbrown;
            .gradient-lightgrey;  
            border : 1px solid @gray-dark;
            margin : 0 5px 10px 5px;
            padding : 10px;
            border : 0px none;
            
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            border-radius: 3px;
            
            -webkit-box-shadow : 0 0px 1px !important;
            box-shadow : 0 0px 1px !important;
            
            h3 {
                 margin : 10px 0px 30px 0px;
                 padding-bottom : 10px;
                 width : 100%;
                 color : @brand-primary-two;
                 border-bottom : 1px solid @brand-primary-two-light;
            }
        }
    }
}



// CONTENT - ACCORDION STYLING
.accordion {
    padding : 10px 20px;
    .gradient-lightgrey;
    border : 0px none;
    
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    
    .accordion-content {
        padding : 20px;
        background-color : @white;
        border : 0px none;
        
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
    }
    .accordion-title {
        font-size : 19px !important;
    }
}

// CONTENT - TABLE STYLING
table {
    
    &.genconttable {
        margin-bottom : 20px;
        
        th {
            background-color : @gray-light !important;
            border : 1px solid @gray-light !important;
            padding : 10px;
        }
        td {
            border : 1px solid @gray-light !important;
            padding : 10px;
            text-align : center;
        }
    }
}


// PERFORMING ARTS BROCHURE - MAIN PAGE
.performing-arts-brochure {
    footer {
        margin-top : 0px !important;
    }
}
.abs {
    .zeropm;
    position : relative;
    background-color : rgba(255,255,255,0.75);
    
    .top {
        .zeropm;
        width : 100%;
        
        .page-header {
            .zeropm;
            padding-top : 40px;
            .gradient-darkblue;
            border-bottom : 0px;
        
            h1 {
                font-size : 16px !important;
                color : @white !important;
                letter-spacing: auto !important;
            }
            .pabheadwords {
                font-size : 14px;
                color : @white;
            }
        }
    }
}
#paenv {
    .zeropm;
    
    .perfslistbox {
        .zeropm;
        position : relative;
        
        .perfslistboxtixbutton {
            position : absolute;
            right : 10px;
            top : 10px;
            font-size : 12px!important;
        }
        
        &.pfmainperf {
            .zeropm;
            width : 100%;
            height : 550px;
            clear : both;
            
            .perftitleexcerpt {
                
                h3 {
                    
                    small {
                        color : @white;
                    }
                }
            }
            
            .perfslist {
                height : 550px;
                
                .innerpad {
                    
                    .perfsbox {
                        height : 550px;
                    }
                }
            }
        }
        .perfslist {
            .zeropm; 
            height : 350px;
            display : block;      
            
            .innerpad {
                .zeropm;
                
                .perfsbox {
                    .zeropm;
                    height : 350px;
                    position : relative;
                
                    .perftitleexcerpt {
                        .zeropm;
                        padding : 10px 0px;
                        width : 100%;
                        position : absolute;
                        bottom : 0px;
                        background-color : rgba(255,255,255,0.6);
                        
                        .showtimes {
                            line-height : 1em; 
                        }
                        
                        h3 {
                            .zeropm;
                            margin-bottom : 10px;
                            width : 100%;
                            text-align : center;
                        }
                        .noticesoldout {
                            padding-top : 10px;
                            padding-bottom : 2px;
                            width : 100%;
                            .gradient-darkblue;
                            color : @white;
                        }
                    }
                }
            }
        }
        
        &.perfsarchived {
            //border : 1px dotted red;
            text-align : center;
            
            .perfsbox {
                margin-left : auto !important;
                margin-right : auto !important;
            }
            
            .perftitleexcerpt {
                background-color : transparent !important;
                position : relative !important;
                
                h3 {
                    font-size : 14px !important;
                }
            }
        }  
    }
}
@media only screen and (max-width: @screen-sm) {
#paenv {
    .perfslistbox {
        &.perfsarchived {
            height : 380px !important;
            .perfsbox {
                width : 300px !important;
                height : 300px !important;
                -webkit-border-radius: 150px !important;
                -moz-border-radius: 150px !important;
                border-radius: 150px !important;      
            }
            .perftitleexcerpt {
                top : 300px !important;
            }
        }
    }
}
}
@media only screen and (min-width: @screen-sm) and (max-width: @screen-md) {
#paenv {
    .perfslistbox {
        &.perfsarchived {
            height : 330px !important;
            .perfsbox {
                width : 250px !important;
                height : 250px !important;
                -webkit-border-radius: 125px !important;
                -moz-border-radius: 125px !important;
                border-radius: 125px !important;
            }
            .perftitleexcerpt {
                top : 250px !important;
            }
        }
    }
}
}
@media only screen and (min-width: @screen-md) and (max-width: @screen-lg) {
#paenv {
    .perfslistbox {
        &.perfsarchived {
            height : 280px !important;
            .perfsbox {
                width : 200px !important;
                height : 200px !important;
                -webkit-border-radius: 100px !important;
                -moz-border-radius: 100px !important;
                border-radius: 100px !important;
            }
            .perftitleexcerpt {
                top : 200px !important;
            }
        }
    }
}
}
@media only screen and (min-width: @screen-lg) and (max-width: 1750px) {
#paenv {
    .perfslistbox {
        &.perfsarchived {
            height : 260px !important;
            .perfsbox {
                width : 180px !important;
                height : 180px !important;
                -webkit-border-radius: 90px !important;
                -moz-border-radius: 90px !important;
                border-radius: 90px !important;
            }
            .perftitleexcerpt {
                top : 180px !important;
            }
        }
    }
}
}
@media only screen and (min-width: 1750px) and (max-width: 5000px) {
#paenv {
    .perfslistbox {
        &.perfsarchived {
            height : 360px !important;
            .perfsbox {
                width : 280px !important;
                height : 280px !important;
                -webkit-border-radius: 140px !important;
                -moz-border-radius: 140px !important;
                border-radius: 140px !important;
            }
            .perftitleexcerpt {
                top : 280px !important;
            }
        }
    }
}
}


// PERFORMING ARTS BROCHURE - POST PAGE
.single-show {
    footer {
        margin-top : 0px;
    }
}
.mainshowcontent {
    margin : 0px 50px 30px 50px;
    padding-top : 375px;
    padding-bottom : 20px;
    
    .showmoneybox {
        background-color : @brand-primary-six;
        width : 100%;
        position : relative;
        margin-bottom : 20px;
        border : 0px none;
        min-height : 75px;
        
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        
        .showprice {
            padding-left : 10px;
            padding-top : 10px;
        }
        .btnbut {
            float : right;
            position : absolute;
            top : 10px;
            right : 10px;
        }
    }
    .brocbut {
        margin-bottom : 5px;
        font-size : 13px;
        padding : 5px;
    }
    .noticesoldout {
        padding-top : 10px;
        padding-bottom : 10px;
        margin-bottom : 5px;
        text-align : center;
        font-weight : bold;
        font-size : 20px;
        background-color : @brand-primary-three;
        color : @white;
        border : 0px none;
        
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        
        p {
            margin-bottom : 0px;
        }
    }
    .showsmain {
        border : 0px none;
        
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
    }
    .page-header {
        background-color : transparent;
        border-bottom : 0px none;
    }
    .showposter {
        //height : 400px;   
    }
    .showtimes {
        text-align : center;
        width : 100%;
        font-size : 0.9em;
        
        .startdate {
            font-weight : bold;
            font-size : 1.1em;
            color : @brand-primary-two;
        }
        .enddate {
            font-weight : bold;
            font-size : 1.1em;
            color : @brand-primary-two;
        }
        .showtime {
            font-weight : bold;
            font-size : 1.1em;
            color : @brand-primary-two;
        }
    }
}



// PERFORMING ARTS LANDING - Choice page
.palmbox {
    padding-left : 5px;
    padding-right : 5px;
    
    a {
        &:hover {
            text-decoration : none;
        }
    }
    
    .palminfo {
        height : 400px;
        position : relative;
        border : 0px none;
        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.5);
        
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        margin-bottom : 30px;
        
        background-position : center center;
        background-repeat : no-repeat;
        background-size : cover;
         
        &.brochurebox {
            background-image : url("http://www.fallibroome.org/fallibroome2015/wp-content/uploads/2015/02/unnamed.17jpg.jpg");
        }   
        &.coursebox {
            background-image : url("http://www.fallibroome.org/fallibroome2015/wp-content/uploads/2015/02/fallibroome-195.jpg");
        }
        
        .palinfoinner {
            padding : 20px;
            position : absolute;
            bottom : 0px;
            width : 100%;
            background-color : rgba(255,255,255,0.75);
            
            small {
                color : @brand-primary-one;
                font-size : 16px;
            }
        }
    }
}




// General content words table
table {
    &.contentwordstable {
        padding-left: 0px;
        border-top : 1px solid @brand-primary-two;
        border-bottom : 1px solid @brand-primary-two;
        width : 100%;
        
        tr {
            padding : 10px 15px;
                
            th {
                background-color : @gray;
                color : @white;
                padding : 10px;
            }
            td {
                background-color : lighten(@gray-lighter, 20%);
                padding : 10px;
            }
            
            &:nth-child(odd){
                background-color : @gray-lighter;
            }
        }
    }
}

// Policies list
.listpolicies {
    padding-left : 0px;
    padding-right : 0px;
    
    a {
        -webkit-transition: all 0.3s ease-in-out 0s;
        -moz-transition:    all 0.3s ease-in-out 0s;
        -ms-transition:     all 0.3s ease-in-out 0s;
        -o-transition:      all 0.3s ease-in-out 0s;
        transition:         all 0.3s ease-in-out 0s;
        
        &:hover {
            color : @black !important;
        }
    }
    
    .innerblock {
        color : @brand-primary-one !important;
        
        .listthumb {
            margin-top : 15px;
            margin-left : 15px;
            width : 100px;
            height : 100px;
             
             -webkit-border-radius: 50px;
             -moz-border-radius: 50px;
             border-radius: 50px;
        }
        
        li {
            .gradient-lightgrey !important;
            padding-left : 15px;
            padding-right : 15px;
            
            &:hover {
                color : @black !important;
            }
            
            h4 {
                color : @brand-primary-one !important;
            }
            p {
                color : @brand-primary-one !important;
                font-size : @font-size-small;
            }
            a {
                color : @brand-primary-one !important;
            }
        }
    }
}




// -------------------------------------------------------
// EBB Imports
//@import "imports/iinfou.less";						// OLD?
//@import "imports/homepabox.less";					// OLD?

@import "imports/Webfonts.less"; 					// Webfont references
@import "imports/ContentArea.less"; 				// general page styling
@import "imports/Ofsted.less"; 						// site ofsted check pges
@import "imports/ContentArea-SpecialBoxes.less"; 	// Special content styles (eg. Downloads and related content)
@import "imports/PageHeadings.less";				// content page headings
@import "imports/Header.less";						// general page header bar
@import "imports/MainNavigation.less";				// main navigation
@import "imports/Search.less";						// site search boxes and form
@import "imports/Footer.less";						// general page footer
@import "imports/Buttons.less";						// Extra button (btn) styles

@import "imports/Homepage.less";					// homepage styling
@import "imports/YearGroup.less";					// year group template styles
@import "imports/Uniform.less";						// uniform page layout
@import "imports/Community.less";					// community/charity page layout
@import "imports/KnowledgeOrganiser.less";			// knowledge organiser layout
@import "imports/Curriculum.less";					// curriculum pages layout
@import "imports/Calendar.less";					// calendar page styling

//@import "imports/SixthForm.less";					// sixth form main styles - KEEP AT BASE OF LIST