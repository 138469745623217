
// -------------------------------------------
// Search Button (magnifying glass icon button)
.searchbuttontop {
	.zeropm;
	margin-top : 5px;
	margin-left : 5px;
	padding-left : 12px;
	border-left : 2px solid @brand-primary-two;
	
	a {
		.zeropm;
		color : @brand-primary-two;
		font-size : @font-size-base;
	}
}
@media only screen and (min-width: 0px) and (max-width: @screen-md) {
.searchbuttontop {
	a {
		font-size : @font-size-base;
	}
}
}


// -------------------------------------------
// Search field styles (default styles)
.search-form {
	
	.search-field {
		font-size : 20px;
		padding : 10px 10px 10px;
		border : 0px none;
		height : 50px;
	}
	.search-submit {
		background-color : @brand-primary-one;
		color : @white;
		font-size : 20px;
		padding : 0px 10px 0px;
		border : 0px none;
		height : 50px;
	}
}


// -------------------------------------------
// Search pop-up box (activated by Search Button)
.topsearch {
	position : relative;
	
    #searchdrop {
        .zeropm;
        background-color : transparent !important;
        z-index : 2000;
  
        position : absolute;
        top : 30px;
        right : 100px;
        
        .input-group {
            margin-top : 0px;
            margin-right : 0px;
            padding-left : 0px;
        }

		.search-form {
			width : 100%;
			
			.search-field {
				height : 45px;
				width : 400px;
				font-family : @font-family-sans-serif;
			}
			.search-submit {
				padding-top: 5px;
				height : 45px;
				font-family : @font-family-serif;
				color : @brand-primary-two;
				
				&.btn-default {
					border : 0px none;
				}
			}
		}
    }	
}


// -------------------------------------------
// Search box - Bottom of page (only visible on xs)
.maintempsearch {
	&.bottomlayout {
		.zeropm;
		padding : 120px 10px 50px 10px;
		text-align : center;
	}
}


// -------------------------------------------

.search-results {
	
    .row {
        //color : @gray-dark !important;
        
        .page-header {
            //color : @gray-dark !important;
        }
        
        .page-intro {
        	padding : 20px 10px;
        	background-color : @white;
        	text-align : center;
        }
        
        .searchresultsexplist {
            list-style-type : none;
            .zeropm;
            
            li {
                //max-width : 900px;
                margin-left : auto;
                margin-right : auto;
                padding-top : 10px;
                padding-bottom : 10px;
                padding-left : 20px;
                padding-right : 20px;
                color : @gray-darker;
                border-bottom : 1px solid @gray-lighter;
                
                &:nth-child(odd) {
                   background-color : @brand-primary-two-lightest; 
                }
                
                a {
                    color : @brand-primary-two;
                    
                    -webkit-transition: all 0.2s ease-in-out 0s;  
    				-moz-transition: all 0.2s ease-in-out 0s;  
    				-ms-transition: all 0.2s ease-in-out 0s; 
    				-o-transition: all 0.2s ease-in-out 0s; 
    				transition: all 0.2s ease-in-out 0s; 
        
                    &:hover {
                        color : @brand-primary-three-dark;
                        text-decoration : none;
                    }
                }
                
                h4 {
                    margin-bottom : 0px;
                }
                .postdate {
                    font-size : @font-size-smaller !important;
                    color : @gray-light;
                    line-height : auto !important;
                    margin-bottom : 0px;
                    font-style : italic; 
                }
                .postexcerpt {
                    margin-bottom : 0px;
                }
                
                .listthumb {
                    background-size : cover;
                    width : 100px;
                    height : 100px;
                    float : right;
                    margin-left : 20px;
                    margin-top : 15px !important;
                     
                    border-radius: 100px;
                    -webkit-border-radius: 100px;
                    -moz-border-radius: 100px;
                }
            }
        }
        
        
        
        
        article {
            max-width : 900px;
            margin-left : auto;
            margin-right : auto;
            padding-top : 10px;
            padding-bottom : 10px;
            padding-left : 20px;
            padding-right : 20px;
            color : @gray-darker;
            border-bottom : 1px solid @gray-lighter;
            
            a {
                color : @brand-primary-three;
                &:hover {
                    color : @brand-primary-three-dark;
                    text-decoration : none;
                }
            }
            
            header {
               
               h2 {
                    .zeropm;
                    color : @brand-primary-three;
                    font-size : 23px;
               }
               time {
                    //display : none;
                    //visibility : hidden;
                    font-size : @font-size-smaller !important;
                    float: left;
                    margin-top : 5px !important;
                    margin-right : 5px !important;
               }
               .byline {
                    //display : none;
                    //visibility : hidden;
                    font-size : @font-size-smaller !important;
               }
            }
            
            .entry-summary {
                
                p {
                    font-size : @font-size-small !important;
                }
            }
        }
    }
}