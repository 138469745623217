// -------------------------------------------
// See ContentArea-SpecialBoxes.less for dynamic content area styling
// -------------------------------------------

// Page content width  
.contentinner {
	padding : 0px 0px 0px 0px;
	margin : 0px auto 0px auto;
	max-width : 1500px;
	background-color : @white; //Commented out whilst working on TYear Group page to give tranparent BG between menu and content
}
.single-yeargroup 
{
	.contentinner {
		background-color : transparent;
	}
}

.contentarea {
	padding : 40px 40px 40px 40px;
	background-color : @white;
	min-height : 400px;
}
.introcontentarea {
    padding : 10px 20px 20px 20px;
    background-color : @white;
    min-height : 400px;
}


// -------------------------------------------
// Basic Content Area styles - Site-wide
.gcsbase {
	text-size : @font-size-cont;
	line-height : ceil((@font-size-cont * 1.75));
}
.gencontentstyles {
	font-family : @font-family-sans-serif;
	.gcsbase;
	text-align : justify;
	text-justify : inter-word;
	color : @gray;
	
	// BASIC TEXT
	p {
		margin-bottom : @font-size-cont;
		.gcsbase;
		
	}
	// HEADINGS - Sizes in variables.less
	h1, h2, h3, h4, h5, h6 {
		font-family : @font-family-serif;
		color : @brand-primary-five;
		text-align : left;
	}
	h1 {
		font-size : @font-size-h1-cont !important;
		margin-bottom : 30px;
	}
	h2 {
		font-size : @font-size-h2-cont !important;
		margin-bottom : 25px;
	}
	h3 {
		font-size : @font-size-h3-cont !important;
		margin-bottom : 20px;
	}
	h4 {
		font-size : @font-size-h4-cont !important;
		margin-top : 20px;
		margin-bottom : 20px;
	}
	h5 {
		font-size : @font-size-h5-cont !important;
		margin-bottom : @font-size-cont;
		margin-top : 20px;
	}
	h6 {
		font-size : @font-size-h6-cont !important;
		margin-bottom : @font-size-cont;
		margin-top : 20px;
	}
	bold, strong {
		color : @brand-primary-five;
	}
	// LINKS
	a {
		color : @brand-primary-five;
		font-weight : bold;
		.basictransition;
		
		&:hover {
			color : @brand-primary-two;
			text-decoration : none;
		}
	}
	// LISTS
	ul, ol {
		margin-bottom : 30px;
	}
	ul {
		
	}
	ol {
		padding-left : 0px;
		list-style : none;
		counter-reset : item;
		
		li {
			counter-increment : item;
			margin-bottom : @font-size-cont;
		}
		li:before {
		   margin-right : 10px;
		   content : counter(item);
		   background : @brand-primary-five;
		   border-radius : 100%;
		   color : @white;
		   width : 1.5em;
		   text-align : center;
		   display : inline-block;
		   font-family : @font-family-serif;
		}
	}
	li {
		margin-bottom : @font-size-cont;
	}
	// TABLE
	table {
		width : 100%;
		border-bottom : 2px solid @brand-primary-five;
		font-size : ceil((@font-size-cont * 0.9));
		
		tr {
			
			&:nth-child(odd) {
				background-color : @brand-primary-five-lighter;
			}
			th, td {
				padding : 10px 10px;
			}
			th {
				background-color : @brand-primary-five;
				color : @white;
				font-family : @font-family-serif;
				font-size : ceil((@font-size-cont * 1.25));
				font-weight : 100;
			}
			td {
				
			}
		}
	}
	// FORMS
	form {
		
		label {
			font-family : @font-family-serif;
			color : @brand-primary-five;
		}
		input {
			padding : 5px 5px;
			font-family : @font-family-sans-serif;
			color : @gray-light;
		}
	}
}


// -------------------------------------------
// RHS - Right hand elements
.well {
	margin-bottom : 40px;
	border-style : none;
	box-shadow : none;
	background-color : @gray-lighter;
	
	background-image : url(../img/BG-Star-LtoR-002-fade.svg);
	background-attachment : fixed;
	background-position : center top;
	background-repeat : no-repeat;
}

.righthandside {
	.well;
	
	h3.rhsboxh3 {
		margin-top : 0px;
		font-size : @font-size-h3 !important;
	}
}

// RHS - Further posts / news box
.righthandposts {
	
	.rnpcontent {
		
		.rnpparticle {
			margin-bottom : 10px;
			text-align : left;
			position : relative;
			
			a {
				.basictransition;
				
				&:hover {
					
					.newswords {
						background-color : @brand-primary-two-light;
					}
				}
			}
			
			.newsimage {
				width : 100%;
				height : 150px;
				background-size : cover !important;
				background-repeat : no-repeat !important;
				background-position : center center !important;
			}
			.newswords {
				padding : 10px 10px;
				background-color : @white;
				
				h4 {
					margin-top : 0px;
					margin-bottom : 0px;
					font-size : @font-size-base !important;
				}
			}
			
			.editbutton {
				.btn {
					position : absolute;
					top : 10px;
					right : 10px;
					z-index : 20000;
				}
			}
		}
	}

	&.hideinfo {
		
		.newsdatestamp {
			display : none;
			visibility : hidden;
		}
		.newswordscont {
			display : none; 
			visibility : hidden;
		}
	}
}


// -------------------------------------------
// Page Elements
.homespotlist {
    border-bottom : 1px solid @white;
    padding-left : 0px;
    
    a { 
        display : block;
        
        &:hover {
            text-decoration : none;
        }
    }
    
    li {
        padding : 10px 20px 10px 20px;
        list-style-type : none;
        background-color : darken(@gray-lighter, 5%);
        
        //-webkit-transition : background-color 0.75s; /* For Safari 3.1 to 6.0 */
        //transition : background-color 0.75s;
        //transition-timing-function : ease-in-out;
        
        &:nth-child(odd){
            background-color : @gray-lighter;
        }
        
        &:hover {
            background-color : initial;   
        }
        
        .listthumb {
             margin-left : 10px;
             position : relative;
             right : 5px;
             background-position : center;
             width: 80px;
             height: 80px;
             float : right;
             
             background-size : cover;
             
             //-webkit-border-radius: 38px;
             //-moz-border-radius: 38px;
             //border-radius: 38px;
        }
        .listwords {
            padding-left : 5px;
        }
    }
}


// -------------------------------------------
// Hide WP Related Posts
#jp-relatedposts {
	display : none;
	visibility : hidden;
}


// -------------------------------------------
// PAGE VARIANTS

// post_iFrame
.iframelayout {
	//border : 2px solid @gray-lighter;
}



// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// TEMPLATE - Vacancies
.page-template-template-vacanies {
	
	 .contentarea {
	 	//padding-bottom : 0px;
	 }
}



// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// Common Page content sections
.relatedcontentbox {
	//border : 1px dotted red;

// Staff lists found in StaffListing.less
	
}

// Back button on Curriculum pages (initially)
.undertitlebackbutton {
	background-color : @brand-primary-one;
	
	button {
		.border-zero;
	}
}