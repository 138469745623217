// Template-specific elements
.yeargroup-template-default {
	
	.contentarea {
		padding : 20px 20px !important;
	}
	
	// Set RHS padding to fill space
	.yprhs {
		//.zeropm;
	}
}



// -------------------------------------------
// SECTION BUTTONS
.ypsectionbuttons {
	margin-bottom : 30px;
	box-shadow : 0px 0px 7px rgba(0,0,0,0.3);
	
	li {
		margin-left : 0px !important;
		font-weight : 700;
		font-size : 15px;
		text-align : center;
		
		background-color : @brand-primary-two-light;
		color : @brand-primary-two;

		// Heading
		&:first-child {
			padding : 30px 20px;
			background-color : @brand-primary-one;
			color : @white;
			
			span {
			}
		}

		// buttons		
		a {
			padding : 30px 20px;
			margin-bottom : 0px;
			border-radius : 0px;
			
			background-color : @brand-primary-three-light;
			color : @white;
			
			.transition-header-zeropointtwofive;
			
			&:hover {
				text-decoration : none;
				background-color : @brand-primary-three-lightest-more;
			}
		}

		// alternate button			
		&:nth-child(2n+3) {

			a {
				background-color : @brand-primary-two;
				color : @white;
				
				&:hover {
					background-color : @brand-primary-two-light;
				}
			}
		}
	}
}



// -------------------------------------------
// TAB MENU CONTENT BUTTONS
.yptabcontent {
	margin-bottom : 0px;
	font-family : @font-family-serif;
		
	.btnbacktocurric {
		background-color : @brand-primary-two !important;
	}
	
	li {
		background-color : @brand-primary-two;
		color : @white;

		margin-left : 0px !important;
		text-align : center;
		font-size : @font-size-large;
		background-color : @brand-primary-one;
		color : @brand-primary-two;
		font-family : @font-family-serif;
		
		.active {
			background-color : @brand-primary-two-light;
		}
		
		// buttons links (general)
		a {
			padding : 20px 10px;
			margin-bottom : 0px;
			border-radius : 0px;
			color : @brand-primary-two;
			
			.transition-header-zeropointtwofive;
			
			&:hover {
				text-decoration : none;
				background-color : @brand-primary-two-lightest;
			}
		}

		// alternate button			
		&:nth-child(even) {
			background-color : @brand-primary-one-shade;

			a {
				//background-color : @brand-primary-one-light;
				
				&:hover {
					//background-color : @brand-primary-five-dark;
				}
			}
		}

	}
}



// -------------------------------------------
// LEARNING MANAGER
.ypmeetlm {
	position : relative;
	box-shadow : 0px 0px 7px rgba(0,0,0,0.3);
	text-align : center;
	
	.panel-heading {
		background-color : @brand-primary-one-moretrans;
		border : 0px none;
		color : @white;
		
		small {
			color : @white;
		}
	}
	
	.panel-body {
		.zeropm;
		position : relative;
	}
	
	.panel-footer {
		background-color : @brand-primary-one-shade-trans;
		border : 0px none;
		color : @white;
		
		p {
			
			&.yplmname {
				font-size : 20px;
			}
		}
		.btn {
			font-size : 12px;
		}
	}
}


// -------------------------------------------
// FORM TUTORS - Takes base styles from LEARNING MANAGER styles
.stafflistlayout {
	.well;
	display : flex;
	flex-wrap: wrap;
	
	.ypformtutor {
		display : flex;
		flex-direction: column;
	}	
}
.ypformtutors {
	height : 100%;
	background-color : transparent;
	box-shadow : 0px 0px 0px transparent;
	
	.panel-body {
		background-color : @brand-primary-five;
		
		h3.panel-title { 
			padding : 10px 10px;
			margin-bottom : 0px;
			font-size : @font-size-h4 !important;
			color : @white;
		}
	}
	
	.panel-footer {
		vertical-align : bottom;
		text-align : left;
		background-color : @brand-primary-two;
		
		p {
			font-size : @font-size-smaller;
			line-height : @font-size-base;
			
			strong {
				font-family : @font-family-serif;
				font-size : @font-size-small;
				color : @brand-primary-five;
			}
		}
		
		.ypftemail {
			//font-size : 12px;
		}
	}
	
	.editbutton {
		.btn {
			position : absolute;
			top : 10px;
			right : 10px;
			z-index : 10000;
		}
	}
}


// -------------------------------------------
// LEARNING MANAGER - within content area
.uiclmcontact {
	
	p {
		margin-bottom : 0px;
	}
}


// -------------------------------------------
// FORM TUTORS - within content area
.uicformtutors {
	//.well;
	
	.well {
		display : flex;
		flex-wrap: wrap;
		
		.ypformtutor {
			display : flex;
			flex-direction: column;
			
			.panel-heading {
				h3 {
					font-size : @font-size-h4 !important;
					margin-bottom : 0px !important;
					color : @white;
				}
			}
		}
	}
}
