// -------------------------------------------
// See ContentArea-SpecialBoxes.less for dynamic content area styling
// -------------------------------------------



// Z-index variable to set level of video and slideshow
// Set the Z-index of the .homecarousel layer and use variable to set z-index of other carousel elements
@z-index : -500; 


// -------------------------------------------
// MEDIA ELEMENTS

// Fullscreen video
#myVideo {
	position : fixed;
	right : 0;
	bottom : 0;
	min-width : 100%;
	min-height : 100%;
	z-index : @z-index;
}
.video-container {
	position : fixed;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
	z-index : @z-index;
}
video {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    min-height: 50%;
    min-width: 50%;
}
.videocontrols {
	text-align : center;
	//z-index: 50000;
	z-index : -(round(@z-index*10));
	position : relative;
	top : -135px;
	width : 100%;
	
	button {
		border : 0px none;
		background-color : transparent;
		font-weight : 700;
		color : @white-trans;
		position : absolute;
		top : -10px;
		left : 10px;
		
		.glyphicon {
			position : relative;
			top : 10px;
			font-size : 40px;
		}
	}
}
// Page spacer for video
.vidheight {
	position : relative;
	height : 100vh;
}
// Video down button
.downbutton {
	position : absolute;
	top : -40px;
	
	width : 100%;
	height : 100vh;
	text-align : center;
	display : table;
	
	p {
		display : table-cell;
		vertical-align : bottom;
		width : 100%;
		height : 100%;
		
		a {
			font-size: 70px;
			color: #FFFFFF;
			.transition-header-zeropointone;
					
			&:hover {
				color: rgba(255, 255, 255, 0.5);
			}
		}
	}
}

// Remove gradient from media section
.home {
	.main {
		//.gradient-contentbgbackground;
		background : none;
	}
}



// -------------------------------------------
// SLIDESHOW 

// Top Layout
#homecarousel {
	
}
.homecarousel {
    position : relative;
    height : 100vh;
	//position : fixed !important;
	//position : absolute;
	top : 0;
	right : 0;
	bottom : 0;
	left : 0;
	background-size : cover !important;
	
	min-width : 100%;
	min-height : 100%;
	
	//z-index : @z-index;
	
	&.contentversion {
		margin-top : 20px;
	}
    
    .carousel-inner {
        
        .item {
           background-attachment : fixed !important;
           background-size : cover !important;
           background-position : center center !important;
           //height : 100vh; Commented out to accomodate Year menu and tickertape
           height : 100vh;
        }
    }
	.carousel-indicators {
		display : none;
		visibility : hidden;
	}
}

// Carousel elements
.carousel-control {
	
}
.carousel-indicators {
    display : none;
    visibility : hidden;
}

// Slide content
.innertransbg {
	height : 90vh;
	width : 100%;
	
	padding-top : 100px;
	margin-left : auto;
	margin-right : auto;
}	
.carousel-caption {
	padding-left : 20%;
    top : 0px;
    height : 90vh;
    text-shadow : 0px 0px 10px @black;
	text-align : right;
	
	flex-wrap : wrap;
	display : flex;
	align-items : center;
	justify-content : center;
	align-content : center;
	

	h2 {
		.zeropm;
		margin-bottom : 20px;
		color : @brand-primary-two;
		font-family : @font-family-sans-serif;
		font-size : @font-size-h3;
		font-weight : bold;
		text-shadow : 0px 0px 7px @black;		
		width : 100%;
		text-align : right;
	}
    
    h4 {
    	.zeropm;
		
		small {
			color : @brand-primary-two-lightest;
		}
    }
    p {
    	.zeropm;
		color : @white;
		font-family : @font-family-serif;
		font-size : @font-size-h1;
		line-height : floor((@font-size-h1 * 1.2));
		text-shadow : 0px 0px 7px @black;
    }
}


@media only screen and (min-width: 0px) and (max-width: @screen-xs) {
.carousel-caption {
	h4 {
		font-size : 3.5em;
	}
	p {
		//margin-top : 10px;
		font-size : 2em;
	}
}
}
@media only screen and (min-width: @screen-xs) and (max-width: @screen-sm) {
.carousel-caption {
	h4 {
		font-size : 4em;
	}
	p {
		//margin-top : 10px;
		font-size : 2em;
	}	
}
}
@media only screen and (min-width: @screen-sm) and (max-width: @screen-md) {
.carousel-caption {
	h4 {
		font-size : 4em;
	}
	p {
		//margin-top : 10px;
		font-size : 2em;
	}	
}
}
@media only screen and (min-width: @screen-md) and (max-width: @screen-lg) {
.carousel-caption {
	h4 {
		font-size : 4.5em;
	}
	p {
		//margin-top : 15px;
		font-size : 2.5em;
	}	
}
}
@media only screen and (min-width: @screen-lg) and (max-width: 4000px) {
.carousel-caption {
	h4 {
		font-size : 5em;
	}
	p {
		//margin-top : 20px;
		font-size : 3em;
	}	
}
}







/*
// Carousel resize
@media only screen and (min-width: 0px) and (max-width: @screen-xs) {
.homecarousel {
    height : 350px;
    .carousel-inner {
        .item {
           height : 350px !important;
            img {
                height : 350px;
            }
        }
    }
    .homestrapline {
        span {
            font-size : 20px !important;
        }
    }
}
}
@media only screen and (min-width: @screen-xs) and (max-width: @screen-sm) {
.homecarousel {
    height : 400px;
    .carousel-inner {
        .item {
           height : 400px !important;
            img {
                height : 400px;
            }
        }
    }
    .homestrapline {
        span {
            font-size : 30px !important;
        }
    }
}
}
@media only screen and (min-width: @screen-sm) and (max-width: @screen-md) {
.homecarousel {
    height : 500px;
    .carousel-inner {
        .item {
           height : 500px !important;
            img {
                height : 500px;
            }
        }
    }
}
}
@media only screen and (min-width: @screen-lg) and (max-width: 3000px) {
.homecarousel {
    height : 800px;
    .carousel-inner {
        .item {
           height : 800px !important;
            img {
                height : 800px;
            }
        }
    }
}
}
*/





// -------------------------------------------
// General content section layout
.homecontentsection {
	position : relative;
	
	.homecontentsectioninner {
		padding-left : 20px;
		padding-right : 20px;
		padding-bottom : 50px;
		margin-left : auto !important;
		margin-right : auto !important;
		max-width : 1500px !important;
		background-color : transparent;
		position : relative;
	}
}
// Mobile screen version
@media only screen and (min-width: 0px) and (max-width: @screen-sm) {
#mobileclass {
	
	.homecontentsectioninner {
		padding-left : 0px;
		padding-right : 0px;
		padding-bottom : 0px;
	}
}
}


// -------------------------------------------
// Homepage initial base-positioned section
.hcbotfeatures {
	background-color : @brand-primary-one;
	position : absolute;
	bottom : 0px;
	left : 0px;
	width : 100%;
	
	.hcbfinner {
		margin-left : auto;
		margin-right : auto;
		max-width : 1500px;
	}
}
// Mobile screen version
@media only screen and (min-width: 0px) and (max-width: @screen-sm) {
.hcbotfeatures {
	position : relative;
}
}


// -------------------------------------------
// Year Hub Links (List Version) - Also for footer links menu
.homeyearlinkslistouter {
	background-color : @brand-primary-one;
	
	.homeyearlinkslist {
		margin-left : auto;
		margin-right : auto;
		max-width : 1500px;
	}
}
.homeyearlinkslist {

	// main button style
	li {
		margin-left : 0px !important;
		text-align : center;
		font-size : @font-size-large;
		background-color : @brand-primary-one;
		color : @brand-primary-two;
		font-family : @font-family-serif;

		// Heading
		&:first-child {
			padding : 10px 20px;
			background-color : @brand-primary-two;
			color : @white;
			
			span {
			}
		}

		// buttons links (general)
		a {
			padding : 10px 20px;
			padding-bottom : 75px;
			border-radius : 0px;
			color : @brand-primary-two;
			font-size : @font-size-large;
			width : 100%;
			height : 100%;
			display : block;
			
			.transition-header-zeropointtwofive;
			
			&:hover {
				text-decoration : none;
				background-color : @brand-primary-two-lightest;
			}
		}

		// alternate button			
		&:nth-child(2n+3) {
			background-color : @brand-primary-one-shade;

			a {
				//background-color : @brand-primary-one-light;
				
				&:hover {
					//background-color : @brand-primary-five-dark;
				}
			}
		}
		
	}
}
// Homepage tweeks
.page-template-template-homepage {
.homeyearlinkslist {

	// main button style
	li {

		// buttons links (general)
		a {
			padding-bottom : 10px;
		}
	}
}
}


// -------------------------------------------
// Social Media
// see Social.less


// -------------------------------------------
// Emergency Notices Styling
.homeschoolnotice {
	text-align : center;
	
	.hsninner {
		padding : 20px 10px;
		margin : 0px auto;
		max-width : 1500px;
		text-align : left;
		font-size : @font-size-base;
	}
	
	&.snownotice {
		background-color : rgba(255,0,0,0.25);
		
		h2 small {
			color : @white;
		}
	}
}


// -------------------------------------------
// Marquee text
.hptickertape {
	background-color : @brand-primary-one;
	
	marquee {
		padding-top : 7px;
		padding-bottom : 5px;
		
		p {
			margin-bottom : 0px;
			color : @white;
			font-size : @font-size-small;
			line-height : @font-size-small;
			font-weight : 100;
		}
		
		a {
			color : @brand-primary-two;
			
			&:hover {
				color : @white;
				text-decoration : none;
			}
		}
	}
}


// -------------------------------------------
// Homepage Spotlights
#hpspotlights {
	background-color : @gray-lighter;
	margin-bottom : 80px;
	
	.homecontentsectioninner {
		padding-bottom : 0px;
	}
}
.homespotlightboxes {
	margin : 20px 0px 0px 0px;
	padding : 0px 0px 0px 0px;
	
	.hslb {
		.zeropm;
		background-size : cover !important;
		background-position : center center !important;
		position : relative;
		height : 200px;
		
		transition : all 0.5s;
		
		a.hslblinkbox {
			color : @brand-primary-one;
			
			width : 100%;
			height : 100%;
			display : inline-block;
			
			position : absolute;
			top : 0px;
			left : 0px;
			
			&:hover {
				text-decoration : none;
			}
			span {
				font-size : 0px;
				display : none;
			}
		}
		
		.post-edit-link {
			font-size : 10px !important;
			
			position : absolute;
			bottom : -22px;
			right : 15px;
			z-index : 20000;
		}
		
		.hslbbox {
			padding : 20px 20px;
			position : absolute;
			top : 0px;
			left : 0px;
			height : 100%;
			width : 100%;
			display : inline-block;
			
			transition : height 0.5s;
			
			.hslbboxinner {
				margin-bottom : 30px;
				border : 0px solid @white;
				height : 100%;
				position : relative;
			
    			.hslbheadbg {
    				width : 100%;
    				background-color : @white-moretrans;
    				
					position : absolute;
					bottom : 0px;
					left : 0px;
    				
    				h3 {
    					.zeropm;
    					padding-top : 10px;
	    				padding-bottom : 10px;
	    				margin-bottom : 1px;
	    				font-size : @font-size-large;
	    				line-height : @font-size-large;
	    				text-align : center;
	    					
    					background-color : @brand-primary-two;
    					font-family : @font-family-serif;
    					color : @white;
    					
    					position : absolute;
    					top : 10px;
    					width : 100%;
    				}
    			}
    			
    			.hslbexcerpt { 
    				font-family : @font-family-sans-serif;
    				font-size : @font-size-small;
    				line-height : @font-size-base;
    				color : @white;
    				
    				opacity: 0.0;
    				filter: alpha(opacity=0);
    				
    				p {
	    				padding : 10px 10px;
	    				font-family : @font-family-sans-serif;
	    				font-size : @font-size-small;
	    				line-height : @font-size-base;
    					color : @white;
    				}
    			}
			}
		}
		
		&:hover {
			text-decoration : none;
			
    		.hslbbox {
    			background-color : fade(@brand-primary-two, 50%);
				
    			transition : all 0.5s;
    			
    			.hslbboxinner {
    				//border : 1px solid @white;
    				background-color : fade(@white-moretrans, 50%);
    				
    				.hslbheadbg {
    					background-color : @white;
    					
    					h3 {
	    					margin-bottom : 1px;
    					}
    				}
    				
    				.hslbexcerpt { 
	    				opacity: 1.0;
	    				filter: alpha(opacity=100);
    				}
    			}	    			
    		}
		}
	}
	@media (min-width: @screen-xs-min) { 
		.hslb {
			height : 300px;
			margin-bottom : 40px;
			
			.hslbbox {
				
			}
		}
	} 
	@media (min-width: @screen-sm-min) { 
		.hslb {
			height : 200px;
			margin-bottom : 40px;
			
			.hslbbox {
				
			}
		}
	} 
	@media (min-width: @screen-md-min) { 
		.hslb {
			height : 200px;
			margin-bottom : 0px;
			
			.hslbbox {
				
			}
		}
	} 
	@media (min-width: @screen-lg-min) { 
		.hslb {
			height : 200px;
			margin-bottom : 0px;
			
			.hslbbox {
				
			}
		}
	}	
}


// -------------------------------------------
// News Section
// See ContentArea-SpecialBoxes.less for box styles.
#hpnews {
	
	.nhpintrobox {
		.hpintrobox;
	}
}


// -------------------------------------------
// Events Section
// See ContentArea-SpecialBoxes.less for box styles.
#hpevents {
	background-color : @gray-lighter;
	background-image : url(../img/BG-Star-LtoR-002-fade.svg);
    background-attachment : fixed;
    background-position : center top;
    background-repeat : no-repeat;
	
	.nhpintrobox {
		.hpintrobox;
	}
}


// -------------------------------------------
// Ofsted Section
#hpofsted {
	padding-top : 60px;
	padding-bottom : 60px;
	background-color : #fefefb;
	
	.hpolayout {
		text-align : center;
		
		// Main block title
		h3 {
			font-family : @font-family-serif;
			color : @brand-primary-two;
		}
		// Small intro text
		.hpolintro {
			margin-left : 30%;
			margin-right : 30%;
			font-family : @font-family-sans-serif;
			font-size : @font-size-smaller;
			line-height : @font-size-smaller;
			font-weight : 100;
			color : @gray-light;
		}
		// Graphics and criteria
		.hpolcriteria {
			padding : 20px 20px;
			
			img {
				margin-left : auto;
				margin-right : auto;
				width : 70%;
			}
			.hpolccriteria {
				margin-bottom : 2px;
				font-family : @font-family-serif;
				color : @brand-primary-two;
				font-size : @font-size-h5;
				line-height : @font-size-h5;
			}
			.hpolcjudgement {
				font-family : @font-family-serif;
				color : @brand-primary-one;	
				font-size : @font-size-h4;
				line-height : @font-size-h4;
			}
		}
	}
}


// -------------------------------------------
// Social Section
#hpsocial {
	background-color : @gray-lighter;
	background-image : url(../img/BG-Star-LtoR-002-fade.svg);
    background-attachment : fixed;
    background-position : center top;
    background-repeat : no-repeat;
    
    .sfintrobox {
    	.hpintrobox;
    }
    
    .col-xs-12, .col-sm-6 {
    	padding : 30px 30px;
    }
    
    .feedbox {
    	
    	&.fbposts {
    		background-color : @brand-primary-two-light;
    	}
    	&.tweets {
    		background-color : @gray-light;
    	}
    }
}


// -------------------------------------------
// Homepage title blocks
.hpintrobox {
	padding-top : 40px;
    padding-bottom : 40px;
    font-family : @font-family-serif;
    font-size : @font-size-h2;
    
    h3 {
		font-size : @font-size-h2;
	}
}
    