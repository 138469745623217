
.post-type-archive-tribe_events,
.single-tribe_events
{

	// Move block up so title meets Header
	.contentarea {
		padding-top : 0px !important;
	}
	
	// Get rid of edit button in Header
	.page-header {
		height : 150px !important;
		
		.post-edit-link {
			display : none;
			visibility : hidden;
		}
	}
	
	// General Calendar styles
	#tribe-events {
		padding-top : 0px !important;
		
		// calendar header
		.tribe-events-title-bar {
			padding : 0px 10px;
			background-color : @brand-primary-one !important;
			color : @white;
			
			h1 {
				margin-top : 0px;
				padding-top : 20px;
				padding-bottom : 20px;
				
				&.tribe-events-page-title {
					color : @white !important;
				}
			}
		}
		
		// Event single styles
		.tribe-events-single {
			padding-top : 0px;
			padding-bottom : 0px;
			
			.tribe-events-back {
				padding : 0px 10px;
				margin-bottom : 0px;
				background-color : @brand-primary-one !important;
				color : @white;
			}
			
			h1 {
				padding : 20px 10px;
				background-color : @brand-primary-one !important;
				font-size : @font-size-h2 !important;
				color : @white;
				text-align : center;
			}
			
			.tribe-events-schedule {
				padding : 20px 10px;
				margin-top : 0px;
				background-color : @brand-primary-one-light !important;
				color : @white;
				text-align : center;
				
				h2 {
					.zeropm;
					font-size : @font-size-h3 !important;
					color : @white;
				}
			}
		}
		
		// Event titles
		h3 {
			font-size : @font-size-small !important;
		}
		
		// Holiday Day style
		.tribe-events-category-holiday {
			height : 85%;
			background-color : @gray;
		}
	}
}