// FOOTER
.home {
    footer {
        margin-top : 0px !important;
    }
}
footer {
    border-top : 15px solid @brand-primary-two;
    background : @brand-primary-one;
    margin-top : 0px;
    color : @white;
    
    background-image : url(../img/BG-Star-LtoR-001.svg);
    background-attachment : fixed;
    background-position : center top;
    background-repeat : no-repeat;
    
    // General Footer styling
    .footboxes {
        padding : 150px 10px 50px 10px;
        margin : 0px;
        font-size : @font-size-base;
    	background-color : transparent !important;
    	font-family : @font-family-serif;
    	
    	//border : 3px solid yellow !important;
    
        &.fbleft {
            text-align : left;   
        }
        &.fbcentre {
        	
        }
        &.fbright {
            text-align : right;   
        }
    	
    	// General text styles
    	h4 {
    		font-size : 20px !important;
    		color : @brand-primary-two;
    	}
    	h5 {
			margin-top : 20px !important;
			margin-bottom : 0px !important;
    		color : @brand-primary-two;
    		font-size : @font-size-base;
    		font-family : @font-family-sans-serif;
    		font-weight : bold !important;
    	}
    	.fgen {
    		font-family : @font-family-serif;
    	}
    	
    	// Headteacher section
    	.headprofile {
    		
			.hpthumb {
				margin-right : 10px;
				margin-bottom : 30px;
				background-size : cover !important; 
				background-position : center center !important;  
				height : 120px; 
				width : 120px; 
				border-radius : 60px;
				float : left;
			}
			.hpdetails {
				padding-top : 30px;
				
				.hpdname {
					margin-bottom : 0px;
					font-family : @font-family-serif !important;
					font-size : 20px !important;
				}
				.hpdtitle {
					font-family : @font-family-sans-serif;
					color : @brand-primary-two;
					font-weight : bold !important;
					font-size : @font-size-small;
				}
			}
    	}
    	
    	// Social and links list
		.footlink {
		    .zeropm;
		    margin-top : 40px;
		    text-align : center;
		    
		    li {
		        .zeropm;
		        list-style-type : none;
		        display : inline-block;
		        margin-right : 20px;
		        
		        &:hover {
		            opacity: 0.4;
		            filter: alpha(opacity=40); /* For IE8 and earlier */   
		        }
		        
		        a {
		            color : @brand-primary-two;
		        }
		        
		        .linklogo {
		            height : 40px;
		            width : 40px;
		            display : block;
		            
		            span {
		                display : none;
		                visibility : hidden;
		            }
		            
		            &.linkfb {
		                background : url("../img/icon_facebook_sm.png") no-repeat top left;
		            }
		            &.linktw {
		                background : url("../img/icon_twitter_sm.png") no-repeat top left;
		            }
		            &.linkyt {
		                background : url("../img/icon_youtube_sm.png") no-repeat top left;
		            }
		            &.linkvim {
		                background : url("../img/icon_vimeo_sm.png") no-repeat top left;
		            }
		            &.linkli {
		                background : url("../img/icon_linkedin_sm.png") no-repeat top left;
		            }
		            &.linkemail {
		                background : url("../img/icon_email_sm.png") no-repeat top left;
		            }
		            &.linkpto {
		                background : url("../img/icon_pto.png") no-repeat top left;
		            }
		            &.linkwisepay {
		                background : url("../img/icon_wisepay_sm.png") no-repeat top left;
		                width : 84px!important;
		            }
		            &.linkgoogleclassroom {
		                background : url("../img/icon_gclass_sm.png") no-repeat top left;
		            }
		        }
		    }  
		}
		
		// Trust information
		.footertrustlogo {
			text-align : right;
			
			img {
				margin-bottom : 20px;
				display : inline !important;
				width : 50%;	
			}
		}
		
		.pmottext {
			
		}
    }
    
    .container {
		margin : 0px auto 0px auto !important;
		max-width : 1500px !important;
    }
    
    .footerlinks {
        padding-left : 0px !important;
        padding-right : 0px !important;
        height : 47px;
        vertical-align : baseline;
        display : none;
        visibility : hidden;
    }
	.footerlinksmenu { 
		background : @white;
		text-align : center; 
		height : 47px;
		line-height : 16px;

		li { 
			height:47px; 
			position:relative; 
			display: inline-block; 
			*display:inline; 
			*zoom:1; 
			max-width: 217px;  
			text-align: center; 
			width: 19%;

			a { 
				color: #4c8187; 
				font-size: 14px; 
				background: repeat-x center bottom transparent; 
				border-top: 2px solid transparent; 
				display: block; 
				padding: 15px 0px 19px; 
				text-decoration: none; 
				text-transform: uppercase; 
				position:absolute; 
				bottom: 0; 
				width:102%; 
				
				-webkit-transition : all 0.1s ease-in-out 0s;  
				-moz-transition : all 0.1s ease-in-out 0s;  
				-ms-transition : all 0.1s ease-in-out 0s; 
				-o-transition : all 0.1s ease-in-out 0s; 
				transition : all 0.1s ease-in-out 0s; 
				
                height : 52px;

				&:hover { 
					background: url("../img/secondaryLinkBg.png") repeat-x scroll center bottom ;
					border-top: 2px solid transparent;
					color: #fff;
					height: 40px;
					height : 66px;
					text-decoration: none;
				} 
			}
		}
	}

    a {
       

        &:hover {
            
        }
    }
}
@media only screen and (min-width: 0px) and (max-width: @screen-sm) {
footer {
    .footerlinks {
        height : 302px;
		border-bottom : 2px solid @white;
    }
    .footboxes {
    	padding : 70px 10px 50px 10px;

		// Trust information - Small version
		.footertrustlogo {
			text-align : center;
			
			img {
				margin-bottom : 20px;
				display : inline !important;
				width : 40%;	
			}
		}
    	
    	// Headteacher section
    	.headprofile {
    		
			.hpthumb {
				margin-left : auto;
				margin-right : auto;
				margin-bottom : 0px;
				background-size : cover !important; 
				background-position : center center !important;  
				height : 120px; 
				width : 120px; 
				border-radius : 60px;
				float : none;
			}
			.hpdetails {
				padding-top : 10px;
				padding-bottom : 40px;
				
				.hpdname {
					margin-bottom : 0px;
					font-family : @font-family-serif !important;
					font-size : 20px !important;
				}
				.hpdtitle {
					font-family : @font-family-sans-serif;
					color : @brand-primary-two;
					font-weight : bold !important;
					font-size : @font-size-small;
				}
			}
    	}
    }
	.footerlinksmenu { 
		padding : 0px;
		margin : 0px;
		width : 100%;
		clear : both;
		height : 300px;

		li { 
			height : auto; 
			display : block; 
			max-width : 100%;  
			text-align : center; 
			width : 100%;
			position : static;

			a { 
				background : url("../img/secondaryLinkBg.png") repeat-x scroll center bottom;
				color : @white;
				border-top : 2px solid @white;
				padding : 10px 0px 20px !important;
				position : static;
				width : 100%;
				height : 60px;

				&:hover { 
					background : url("../img/secondaryLinkBg.png") repeat-x scroll center bottom;
			        border-top: 2px solid @brand-primary-two-dark;
				    height : 60px;
				} 
			}
		}
	}
    .footlink {
        margin-left : auto!important;
        margin-right : auto!important;
    }
}
.fbleft {
    border-top : 1px solid @white;
    text-align : center!important;
}
.fbcentre {
	border-top : 1px solid @white;
}
.fbright {
    border-top : 1px solid @white;
    text-align : center!important;
}
}
// Make bottom links only visible on homepage
.page-template-template-homepage-php {
footer {
    .footerlinks {
        display : block;
        visibility : visible;
    }   
}
}

// MAT Footer Links
.matlinksbase {
    //background-color : @brand-primary-one-light;
    
    ul {
        list-style-type : none;
		padding : 0px;
		margin : 0px;
		width : 100%;
		clear : both;
		border-bottom : 2px solid @white;

		li { 
			max-width : 100%;  
			text-align : center; 
			width : 100%;

			a { 
				color : @white;
				padding : 10px 0px 10px !important;
				width : 100%;
				display : block;

				&:hover { 
					background-color : @brand-primary-one-lightish;
					text-decoration : none;
				} 
			}
		}
	}  
}


// -------------------------------------------
// Circular logo on footer
.homebotlogo {
	width : 100%;
	height : 0px;
	text-align : center;
	
	.homebotlogoinner {
		margin-left : auto;
		margin-right : auto;
		height : 150px;
		width : 150px;
		border-radius : 75px;
		background-color : @brand-primary-two;
		background-image: url("../img/logo-foot-circle-001.png");
		background-size : 115px;
		background-position : center center;
		background-repeat : no-repeat;
		
		position : relative;
		top : -82px;
	}
}
