

.btn {
	//font-family : @font-family-serif;
	font-family : @font-family-sans-serif;
	color : @white;
	font-weight : normal;
	//font-size : 1.1em;
	line-height : 0.95em;
	border : 1px solid @gray-lighter;
	border-radius : 0px;
	
	text-shadow : 0px 0px 1px @white;
	
	&.btn-primary {
		border : 0px none;
		background-color : @brand-primary-one;
		text-shadow : 0px 0px 1px @brand-primary-two;
	}
	&.btn-default {
		border : 1px solid @gray-lighter;
	}
	&.btn-success {
		color : @white;
		border : 0px none;
	}
	&.btn-info {
		color : @white;
		border : 0px none;
	}
	&.btn-warning {
		color : @white;
		border : 0px none;
	}
	&.btn-danger {
		color : @white;
		border : 0px none;
	}
	&.btn-link {
		border : 1px solid @gray-lighter;
	}
}
a {
	&.btn {
		//color : @brand-primary-two;
		color : @white;
		font-weight : normal;
		border : 1px solid @gray-lighter;
		
		&.btn-primary {
			text-shadow : 0px 0px 1px @brand-primary-two;
		}
		&.btn-default {
			
		}
		&.btn-success {
			color : @white;
		}
		&.btn-info {
			color : @white;
		}
		&.btn-warning {
			color : @white;
		}
		&.btn-danger {
			color : @white;
		}
		&.btn-link {
			
		}
	}
}