/* Checklist */
.ofstedchecklisttable {
	border : 1px solid #DDDDDD; 
	font-size : @font-size-small !important; 
	width : auto !important;
	border-bottom : 0px none !important;
	
	tr:nth-child(even) {
		background: rgba(0,0,0,0.10);
	}
	th {
		padding : 3px 10px 3px 10px !important;
		font-size : @font-size-small !important;
		background-color : transparent !important;
		color : @brand-primary-one !important;
	}
	td {
		padding : 3px 10px 3px 3px !important; 
		width : 20px; 
		height : 10px; 
		display : table-cell;
		
		&.checked {
			background-color : #00FF00;
		}
	    &.unchecked {
	    	background-color : #FF0000;
	    }
		&.conditional {
			background-color : #FF7A4D;
		}
	}
}

/* General Styles */
.textred {color : #FF0000;}
.textorange {color : #FF7A4D;}

/* Notes Box */
.ofstednotes {
	margin-top : 20px;
	padding : 3px 3px;
	margin-bottom : 5px;
	font-size : 12px;
	line-height : 16px;
	
	p {
		margin-bottom : 5px;
		font-size : 12px;
		line-height : 16px;
	}
	li {
		margin-bottom : 2px !important;
	}
}

/* Tab Menu */
.mainroitabmenu {
	text-align : center;
	
	li {
		margin-bottom : 3px;
		font-family : @font-family-serif;
		
		a {
			border : 1px solid #DDDDDD;
		}
	}
}

/* Term Dates Table */
.termdatestable { 
	border: 1px solid #DDDDDD;
	background-color : #FFFFFF;
	width : 100%; 

	th {
		padding : 5px 10px;
		background-color : #DDDDDD;
		border-top : 1px solid #EEEEEE;
		
		&.termheading {
			background-color : #EEEEEE;
		}
	}
	td {
		padding : 5px 10px;
		border-top : 1px solid #DDDDDD;
	}
}