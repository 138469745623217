.navbar {
	min-height : 30px;
}

.mainnavigation {
	position : relative;
	
	.navlayout {
		//margin-top : 10px;

		.nav {

			&.navbar-nav {
			    
			    a {
			        padding : 9px 8px;
			        //height : 30px;
			        background-color : transparent;
			        background-color : @brand-primary-one-trans;
			        font-family : @font-family-serif;
			        color : @brand-primary-two !important;
			        font-size : @font-size-nav;
			        line-height : @font-size-nav;
			        
			        .transition-header;
			                        
			        &:hover {
			            background-color : @brand-primary-one-moretrans !important;
			            color : @white !important;
			        }
			    }

				// Menu states
				.active {
					border-bottom : 0px none !important;
				
					a {
						background-color : @brand-primary-one !important;
						color : @white !important;
						
						&:hover {
							background-color : @brand-primary-one-dark !important;
						}
						&:focus {
							background-color : @brand-primary-one-dark !important;
						}
					}
				}
				.open {
					a {
						background-color : @brand-primary-five !important;
						color : @brand-primary-two !important;
						
						&:hover {
							background-color : @brand-primary-one !important;
							color : @white !important;	
						}
					}
				}
			}
		}
	}

	// Main drop down styles (columns etc.)
	.dropdown-menu {
	    padding : 0px 0px;
	    margin : 0px 0px;
	    background-color : @brand-primary-one-dark !important;
	
		li {
			a {
				display : inline-block;
				width : 100%;
			}
			&.linkheadingsnolink {
				padding : 9px 9px;
				height : 30px;
				color : @white;
				border-bottom : 1px solid @white-trans;
			}			
		}
	}
	.nocols {
	    .dropdown-menu {
	        .singlecolumns;
	    }
	}

    .halfposition {

		// Three-line menu button
        button {
            margin : 0px 10px 0px 0px;
            height : 30px;
            border : 0px none;
            background-color : @brand-primary-one-trans;
            
            -webkit-border-radius: 0px;
            -moz-border-radius: 0px;
            border-radius: 0px;
            
            z-index : 20000;
            
            .icon-bar {
                background-color : @white;   
            }
        }
    }  

}


// -------------------------------------------
// Nudge menu in to match links on top right
@media only screen and (min-width: @screen-sm) and (max-width: 1510px) {
.mainnavigation {
    padding-right : 25px;
}
}


// -------------------------------------------
// Small screen menu bar
@media only screen and (min-width: 0px) and (max-width: @screen-sm) {
.mainnavigation {
    padding-left : 0px;
    padding-right : 0px;
    padding-bottom : 0px;
    
    text-align : right;
    
    .fullposition {
        padding-left : 0px;
        padding-right : 0px;
        width : 100%;
        z-index : 10000;
        border-top : 0px none;
    }
}
nav {
    
}
.navlayout {
   margin-right : 0px;
}
.navbar-nav {
	margin : 0px 0px;
	
    li {
        width : 100%;
        a {
            color : @white !important;
            &:hover {
                color : @brand-primary-one !important;
            }
        }
    }
}
}


// -------------------------------------------
